import React, { Component } from 'react';

class Contact extends Component {
    render() { 
        return (
            <h1>Contact Page</h1>
        );
    }
}
 
export default Contact;
import React, { Component } from 'react';

class Home extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h1>Prabhuprasad Panda</h1>
                <h3>Software Enggineer at MSys Technologies</h3>

                
            </div>
        );
    }
}
 
export default Home;
import React, { Component } from 'react';

class About extends Component {
    render() { 
        return (
            <h1>About Page</h1>
        );
    }
}
 
export default About;
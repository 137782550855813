import React, { Component } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Home';
import About from './About'
import Contact from './Contact'
import Layout from './Layout';


class Navbar extends Component {
    render() { 
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="about" element={<About />} />
                        <Route path="contact" element={<Contact />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}
export default Navbar;